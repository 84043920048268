export interface Persona {
    // AQEM-1387 - This is optional because it's not always provided in Okta and
    // Okta sends it as null if it's not set.
    department?: string | null;
    displayName?: string | null;
    firstName: string;
    key: string;
    lastName: string;
    licenses: {
        licenseType: string;
        productName: string;
    }[];
    roles: {
        description: string;
        hierarchy: string;
        value: string;
    }[];
    sites?: string[];
    userId: string;
}

export interface MockAvatarUser {
    displayName: string;
    firstName: string;
    lastName: string;
    userId: string;
}

export const AMXPersona = {
    AliciaQualityControl: {
        department: "Quality",
        displayName: "Alicia - Quality Control",
        firstName: "Alicia",
        key: "alicia-quality-control",
        lastName: "Quality Control",
        licenses: [
            {
                licenseType: "Basic",
                productName: "Logbooks",
            },
        ],
        roles: [
            {
                description: "Quality Control",
                hierarchy: "MasterControl > US > Quality Control",
                value: "Quality Control",
            },
        ],
        sites: ["US"],
        userId: "00w92vn0gvtcroume6or",
    } as Persona,
    BrandieManufacturingEngineer: {
        department: "Manufacturing",
        displayName: "Brandie - Manufacturing Engineer",
        firstName: "Brandie",
        key: "brandie-manufacturing-engineer",
        lastName: "Manufacturing Engineer",
        licenses: [
            {
                licenseType: "Full",
                productName: "Logbooks",
            },
        ],
        roles: [
            {
                description: "Manufacturing Engineer",
                hierarchy: "MasterControl > US > Manufacturing Builder",
                value: "Manufacturing Builder",
            },
        ],
        userId: "00w92fp08w6gi6k8e6or",
    } as Persona,
    GiovanniQualityApprover: {
        department: "Quality",
        displayName: "Giovanni - Quality Approver",
        firstName: "Giovanni",
        key: "giovanni-quality-approver",
        lastName: "Quality Approver",
        licenses: [
            {
                licenseType: "Basic",
                productName: "Logbooks",
            },
        ],
        roles: [
            {
                description: "Quality Approver",
                hierarchy: "MasterControl > US > Quality Approver",
                value: "Quality Approver",
            },
        ],
        userId: "00w92fr36j56i09u26or",
    } as Persona,
    KeithAssemblyOperator: {
        department: "Manufacturing",
        displayName: "Keith - Assembly Operator",
        firstName: "Keith",
        key: "keith-assembly-operator",
        lastName: "Assembly Operator",
        licenses: [
            {
                licenseType: "Basic",
                productName: "Logbooks",
            },
        ],
        roles: [
            {
                description: "Assembly Operator",
                hierarchy: "MasterControl > US > Assembly Operrator",
                value: "Assembly Operator",
            },
        ],
        userId: "00w92fvyu08kv576p6or",
    } as Persona,
};

export const DocsPersona = {
    EleanorDocumentManager: {
        displayName: "Eleanor - Doc Control Specialist",
        firstName: "Eleanor",
        key: "eleanor-doc-control-specialist-sysadmin",
        lastName: "Doc Control Specialist",
        licenses: [
            {
                licenseType: "Full",
                productName: "ADocs",
            },
        ],
        roles: [],
        userId: "00u1zjj1as43sNmIM0h8",
    } as Persona,
    GeraldineDocumentManager: {
        displayName: "Geraldine - Document Manager",
        firstName: "Geraldine",
        key: "geraldine-document-manager",
        lastName: "Document Manager",
        licenses: [
            {
                licenseType: "Full",
                productName: "ADocs",
            },
        ],
        roles: [],
        userId: "00u17fswjscKuYRjR0h8",
    } as Persona,
    SarahDocControlSpecialist: {
        displayName: "Sarah - Doc Control Specialist",
        firstName: "Sarah",
        key: "sarah-doc-control-specialist",
        lastName: "Doc Control Specialist",
        licenses: [
            {
                licenseType: "Full",
                productName: "ADocs",
            },
        ],
        roles: [],
        userId: "00u17ft4q4znthjc30h8",
    } as Persona,
};

export const TrainingPersona = {
    GaryTrainingSpecialistSysadmin: {
        displayName: "Gary - Training Specialist Sysadmin",
        firstName: "Gary",
        key: "gary-training-specialist-sysadmin",
        lastName: "Training Specialist Sysadmin",
        licenses: [
            {
                licenseType: "Full",
                productName: "ATraining",
            },
        ],
        roles: [],
        userId: "01u1zjj1as44sNmIM0h8",
    } as Persona,
    JasonTrainingSpecialist: {
        displayName: "Jason - Training Specialist",
        firstName: "Jason",
        key: "jason-training-specialist",
        lastName: "Training Specialist",
        licenses: [
            {
                licenseType: "Full",
                productName: "ATraining",
            },
        ],
        roles: [],
        userId: "00u17fswjscKuYRjR1h9",
    } as Persona,
    RebeccaTrainingManager: {
        displayName: "Rebecca - Training Manager",
        firstName: "Rebecca",
        key: "rebecca-training-manager",
        lastName: "Training Manager",
        licenses: [
            {
                licenseType: "Full",
                productName: "ATraining",
            },
        ],
        roles: [],
        userId: "00u18ft4q5znthjc30h8",
    } as Persona,
};

export const GlobalPersona = {
    KyleServiceConsultant: {
        // NOTE: intenationally provided, all test personas should have a display name for test purposes.
        // NOTE: this solves a lot of flaky test issues, where tests expect to properly display the name
        // of the user - but sometimes this user was randomely selected and didn't display properly.
        displayName: "Kyle - Service Consultant",
        firstName: "Kyle",
        key: "kyle-consultant",
        lastName: "Service Consultant",
        licenses: [
            {
                licenseType: "Full",
                productName: "AEB",
            },
            {
                licenseType: "Full",
                productName: "AQEM",
            },
            {
                licenseType: "Full",
                productName: "Logbooks",
            },
        ],
        roles: [
            {
                description: "System Administrator",
                hierarchy: "System > System Administrator",
                value: "SysAdmin",
            },
        ],
        userId: "00u17fpokxdMZlFmC0h8",
    } as Persona,
    SeamusSysadmin: {
        displayName: "Seamus - SysAdmin",
        firstName: "Seamus",
        key: "seamus-sysadmin",
        lastName: "Sysadmin",
        licenses: [
            {
                licenseType: "Full",
                productName: "AEB",
            },
            {
                licenseType: "Full",
                productName: "AQEM",
            },
            {
                licenseType: "Full",
                productName: "DocumentTranslator",
            },
            {
                licenseType: "Full",
                productName: "Logbooks",
            },
        ],
        roles: [
            {
                description: "System Administrator",
                hierarchy: "System > System Administrator",
                value: "SysAdmin",
            },
        ],
        userId: "00u17fsrfyvlFaghf0h8",
    } as Persona,
};

export const AQEMPersona = {
    // NOTE: Access tokens were created by manually providing JWT values at https://token.dev/
    AmberQualityManager: {
        department: "Quality",
        displayName: "Amber - Quality Manager",
        firstName: "Amber",
        key: "amber-quality-manager",
        lastName: "Quality Manager",
        licenses: [
            {
                licenseType: "Full",
                productName: "AQEM",
            },
            {
                licenseType: "Full",
                productName: "AEB",
            },
        ],
        roles: [
            {
                description: "Role: Quality Manager",
                hierarchy: "MasterControl > US > Quality Manager",
                value: "Quality Manager",
            },
        ],
        sites: ["US"],
        userId: "00u17fss7bvR2xTcG0h8",
    } as Persona,
    AmyFrontline: {
        department: "Quality",
        displayName: "Amy - Frontline",
        firstName: "Amy",
        key: "amy-frontline",
        lastName: "Frontline",
        licenses: [
            {
                licenseType: "View",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Role: Quality Specialist",
                hierarchy: "MasterControl > US > Quality Specialist",
                value: "Quality Specialist",
            },
        ],
        userId: "00u17fsuv0rwHqWRS0h8",
    } as Persona,
    BetsyApprover: {
        department: "Quality",
        displayName: "Betsy - Approver",
        firstName: "Betsy",
        key: "betsy-approver",
        lastName: "Approver",
        licenses: [
            {
                licenseType: "Basic",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Role: Quality Approver",
                hierarchy: "MasterControl > US > Quality Approver",
                value: "Quality Approver",
            },
        ],
        userId: "00u17fswx1elDkagm0h8",
    } as Persona,
    CesarQualityEngineer: {
        department: "Quality",
        displayName: "Cesar - Quality Engineer",
        firstName: "Cesar",
        key: "cesar-quality-engineer",
        lastName: "Quality Engineer",
        licenses: [
            {
                licenseType: "Full",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Role: Quality Engineer",
                hierarchy: "MasterControl > US > Quality Engineer",
                value: "Quality Engineer",
            },
        ],
        userId: "00u17s0uvrpcdblVd0h8",
    },
    HoidSolutionsTemplateManager: {
        displayName: "Hoid - Solution Template Admin",
        firstName: "Hoid",
        key: "hoid-solution-template-manager",
        lastName: "Solutions Template Manager",
        licenses: [
            {
                licenseType: "Full",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Can do anything...",
                hierarchy: "MasterControl > US > Solutions Admin",
                value: "Solutions Admin",
            },
        ],
        userId: "00u1i4ho1dwOrld560h8",
    } as Persona,
    JohnAreaManager: {
        displayName: "John - Area Manager / Investigator",
        firstName: "John",
        key: "john-area-manager",
        lastName: "Area Manager",
        licenses: [
            {
                licenseType: "Basic",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Area Manager",
                hierarchy: "MasterControl > US > Area Manager",
                value: "Area Manager",
            },
        ],
        userId: "00u17fssbey7yx6Tx0h8",
    } as Persona,
    LuigiSystemTemplateSysAdmin: {
        displayName: "Luigi - System Template SysAdmin",
        firstName: "Luigi",
        key: "luigi-system-template-sysadmin",
        lastName: "System Template SysAdmin",
        licenses: [
            {
                licenseType: "Full",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Can do anything...",
                hierarchy: "MasterControl > US > Solutions Admin",
                value: "Solutions Admin",
            },
        ],
        userId: "00u1i4lu1giwah0o560h",
    },
    MarioSystemTemplateAdmin: {
        displayName: "Mario - System Template Admin",
        firstName: "Mario",
        key: "mario-system-template-admin",
        lastName: "System Template Admin",
        licenses: [
            {
                licenseType: "Full",
                productName: "AQEM",
            },
        ],
        roles: [
            {
                description: "Can do anything...",
                hierarchy: "MasterControl > US > Solutions Admin",
                value: "Solutions Admin",
            },
        ],
        userId: "00u1i4m4r10wah0o560h",
    },
};

export const TestPersona = { ...AMXPersona, ...DocsPersona, ...GlobalPersona, ...AQEMPersona };

export const TestPersonaList: Persona[] = Object.keys(TestPersona).map(
    (key) => TestPersona[key as keyof typeof TestPersona],
);

export function randomUserId() {
    return TestPersonaList[Math.floor(Math.random() * TestPersonaList.length)].userId;
}
