/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {
    GetWorkflowsResponse,
    GetWorkflowsResponseFromJSON,
    WorkflowConfigurationPackage,
    WorkflowConfigurationPackageFromJSON
} from '../models';
import * as runtime from '../runtime';

export interface GetLaunchableWorkflowsV2Request {
    appId: string;
    mcApplicationId: string;
    name?: string;
    pageNumber?: number;
    pageSize?: number;
    pcsAppid?: string;
    pcsTenantid?: string;
    pcsUserid?: string;
    pcsRoles?: string;
}

export interface GetWorkflowConfigurationPackageByIdRequest {
    workflowId?: string;
    mcApplicationId?: string;
    pcsAppid?: string;
    pcsTenantid?: string;
    pcsUserid?: string;
    pcsRoles?: string;
}

/**
 * 
 */
export class WorkflowV2Api extends runtime.BaseAPI {

    /**
     * Gets all Workflows that can be launched by the authenticated user. Valid workflows are those where the  authenticated user is found in the event initiator permissions on the first step of a Workflow. Results will exclude all workflows that cannot be directly launched, such as Action Item workflows. 
     * Get all launchable Workflows for a given \'appId\' filtered by optional query parameter(s) 
     */
    async getLaunchableWorkflowsV2Raw(requestParameters: GetLaunchableWorkflowsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetWorkflowsResponse>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getLaunchableWorkflowsV2.');
        }

        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getLaunchableWorkflowsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflows/v2/launchable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkflowsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all Workflows that can be launched by the authenticated user. Valid workflows are those where the  authenticated user is found in the event initiator permissions on the first step of a Workflow. Results will exclude all workflows that cannot be directly launched, such as Action Item workflows. 
     * Get all launchable Workflows for a given \'appId\' filtered by optional query parameter(s) 
     */
    async getLaunchableWorkflowsV2(requestParameters: GetLaunchableWorkflowsV2Request, initOverrides?: RequestInit): Promise<GetWorkflowsResponse> {
        const response = await this.getLaunchableWorkflowsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets Workflow Configuration Package for the given published Workflow ID 
     * Get Workflow Configuration Package by ID
     */
    async getWorkflowConfigurationPackageByIdRaw(requestParameters: GetWorkflowConfigurationPackageByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowConfigurationPackage>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling getWorkflowConfigurationPackageById.');
        }

        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getWorkflowConfigurationPackageById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v2/{workflowId}/package`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowConfigurationPackageFromJSON(jsonValue));
    }

    /**
     * Gets Workflow Configuration Package for the given published Workflow ID 
     * Get Workflow Configuration Package by ID
     */
    async getWorkflowConfigurationPackageById(requestParameters: GetWorkflowConfigurationPackageByIdRequest, initOverrides?: RequestInit): Promise<WorkflowConfigurationPackage> {
        const response = await this.getWorkflowConfigurationPackageByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
