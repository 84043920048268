/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewCorrectionCommandRequestV2
 */
export interface NewCorrectionCommandRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequestV2
     */
    nodeToCorrectId: string;
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequestV2
     */
    comments: string;
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequestV2
     */
    reason: string;
    /**
     * True if every node after the corrected node should be completed again.
False if only the TO node will be corrected.

     * @type {boolean}
     * @memberof NewCorrectionCommandRequestV2
     */
    requireRework?: boolean;
}

export function NewCorrectionCommandRequestV2FromJSON(json: any): NewCorrectionCommandRequestV2 {
    return NewCorrectionCommandRequestV2FromJSONTyped(json, false);
}

export function NewCorrectionCommandRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCorrectionCommandRequestV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeToCorrectId': json['nodeToCorrectId'],
        'comments': json['comments'],
        'reason': json['reason'],
        'requireRework': !exists(json, 'requireRework') ? undefined : json['requireRework'],
    };
}

export function NewCorrectionCommandRequestV2ToJSON(value?: NewCorrectionCommandRequestV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeToCorrectId': value.nodeToCorrectId,
        'comments': value.comments,
        'reason': value.reason,
        'requireRework': value.requireRework,
    };
}

